<template>
  <div class="poster-page page-invite" v-if="values && values[0] && values.length > 0 && values[0].length > 0">
    <div class="show-area">
      <p class="is-blank" v-if="filesCount<values[0].length">{{ filesCount>=0 ? `${filesCount}/${values[0].length} 加载中···` : '无内容' }}</p>
      <div class="is-list" v-else>
        <h1><button @click="toDownloads">点击下载全部图片</button> 或 点击图片单独下载：</h1>
        <img v-for="(item,index) in files" :src="item.file" alt="" :key="'invite_pre_'+index" @click="downloadImg(item.file, item.name)">
      </div>
    </div>
    <div class="sharecard_model"
      :id="`sharecard_model_invite_${values[0][index-1]||''}`"
      v-for="index in maxValueLength"
      :key="'invite_'+index"
      :style="$route.query&&$route.query.test?'position:fixed;z-index:6;top:0;height:100vh;overflow:auto;':''"
    >
      <img v-if="_file&&_file.url" :src="_file.url" alt="" :width="_file.width||1080">
      <slot v-for="g in (parseInt(_group) || 1)">
        <p :class="(_file&&_file.url)?'ab':''" v-if="values[g-1]&&values[g-1][index-1]" v-html="values[g-1][index-1]" :style="styles[g-1][index-1]" :key="values[g-1][index-1]+g+index"></p>
      </slot>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image'

export default {
  props: {
    _file: {
      type: Object,
      default: () => ({})
    },
    _text: {
      type: [Object, Array],
      default: () => ({})
    },
    _group: {
      type: [Number, String],
      default: 1
    }
  },
  data () {
    return {
      files: {},
      filesCount: 0
    }
  },
  computed: {
    maxValueLength () {
      let len = 0
      for (let item of this.values) {
        if (item && item.length > len) {
          len = item.length
        }
      }
      return len
    },
    values () {
      let _text = [] // (this._text.value && this._text.value.split('，')) || [this._text.default || '']
      for (let tIndex in this._text) {
        _text.push([])
        if (this._text[tIndex] && this._text[tIndex].value) {
          _text[tIndex] = this._text[tIndex].value.split('，')
        }
      }
      return _text
    },
    styles () {
      let styles = [] // 'position:absolute;z-index:5;'
      for (let tIndex=0; tIndex<(parseInt(this._group) || 1); tIndex++) {
        tIndex = parseInt(tIndex)
        styles.push([])
        for (let index=0; index<this.maxValueLength; index++) {
          styles[tIndex].push('position:absolute;z-index:5;')
          if (this._text[tIndex] && this._text[tIndex].styles[index]) {
            styles[tIndex][index] += `top:${this._text[tIndex].styles[index].top || 0}px;`
            styles[tIndex][index] += `color:${this._text[tIndex].styles[index].color || '#000'};`
            styles[tIndex][index] += `font-size:${this._text[tIndex].styles[index].fontSize || '16'}px;`
            styles[tIndex][index] += `font-weight:${this._text[tIndex].styles[index].fontWeight || 400};`
            styles[tIndex][index] += `font-style:${this._text[tIndex].styles[index].fontStyle || 'normal'};`
            styles[tIndex][index] += `font-family:${this._text[tIndex].styles[index].fontFamily || 'Alibaba_PuHuiTi_Medium'};`
            styles[tIndex][index] += this._text[tIndex].styles[index].transform ? `transform:${this._text[tIndex].styles[index].transform};transform-origin:left bottom;` : ''
            styles[tIndex][index] += this._text[tIndex].styles[index].left ? (this._text[tIndex].styles[index].left.toString().trim()==='center' ? 'left:50%;' : `left:${this._text[tIndex].styles[index].left}px;`) : ''
            // left： // 》》》》》》
            // if ((tIndex === 1 || tIndex === 2) && this.global.year && this.global.year.toString().includes('2025')) {
            //   if (!this._text[tIndex].left) {
            //     if (tIndex === 1) {
            //       let len = 30 + parseInt(this._text[0].left || 0) + (this._text[0].value || '').length * parseInt(this._text[0].fontSize || 0)
            //       styles[tIndex] += `left:${len}px;`
            //     }
            //     if (tIndex === 2) {
            //       let len = 30 + 12 + parseInt(this._text[0].left || 0) + (this._text[0].value || '').length * parseInt(this._text[0].fontSize || 0) + (this._text[1].value || '').length * parseInt(this._text[1].fontSize || 0)
            //       styles[tIndex] += `left:${len}px;`
            //     }
            //   }
            //   // styles[tIndex]
            // } else {
            //   styles[tIndex] += this._text[tIndex].left ? (this._text[tIndex].left.toString().trim()==='center' ? 'left:50%;' : `left:${this._text[tIndex].left}px;`) : ''
            // }
          }
        }
      }
      return styles
    }
  },
  watch: {
    values: {
      handler (val, oldVal) {
        console.log('📢 values ', val, oldVal, this._text)
        if (val && val[0] && val[0].length > 0 && !this.global.toCompareObject(val, oldVal)) {
          this.$nextTick(() => {
            this.files = {}
            this.filesCount = 0
            if (!(this.$route.query && this.$route.query.test)) {
              this.setCards(val[0])
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async setCards (arr) {
      console.log('📢 set_cards ', arr)
      if (arr && arr.length > 0) {
        for (let name of arr) {
          let id = `sharecard_model_invite_${name||''}`
          await this.setCard(id, name)
        }
      }
    },
    setCard (id, name) {
      return new Promise(async resolve => {
        if (id) {
          // 获取年月日作为文件名-年月日加随机数
          var timers = new Date()
          var fullYear = timers.getFullYear()
          var month = timers.getMonth() + 1
          var date = timers.getDate()
          var randoms = Math.random() + ''
          var theTime = fullYear + '' + (month < 10 ? '0' : '') + month + (date < 10 ? '0' : '') + date
          var fileName = `share${theTime}${randoms.slice(3, 10)}`
          await this.convertToImage(id, name||'').then(res => {
            if (res && typeof res === 'object') {
              // const { imgBlob, fileUrl, imgFile } = res
              const { fileUrl, imgFile } = res
              this.files[id] = { id: id, name: fileName, file: fileUrl, imgFile: imgFile }
              this.filesCount ++
              this.$forceUpdate()
              resolve(1)
            }
          })
        }
      })
    },
    // 
    convertToImage (key, name) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // let modelId = `sharecard_${this.paramsActive.type}_${this.paramsActive.cardid}_${this.paramsActive.detailid || this.paramsActive.id || ''}`
          let id = '#' + key
          let node = document.querySelector(id)
          // let id = '.poster-card'
          // let node = document.querySelector(id)
          console.log('📢 convert_to_image ready ', key)
          // console.log('📢 convert_to_image ready ', key, node)
          // domtoimage.toPng(node, {
          domtoimage.toBlob(node, {
            type: 'image/png', // 设置输出图像的格式为 PNG
            useCORS: true, // 尝试使用 CORS 加载跨域图像
            // filter: (node, options) => {
            //   console.log('📢 domtoimage_filter ', node, options)
            //   // 移除节点的某些样式
            //   // delete node.style.opacity
            //   // delete node.style.position
            //   // delete node.style.bottom
            //   // if (node.style.position === 'absolute') {
            //   //   node.style.position = 'relative'
            //   //   node.style.top = '0'
            //   //   node.style.left = '0'
            //   // }
            // },
            bgcolor: '#ffffff', // 设置背景颜色为白色
            // quality: 1, // 设置 JPEG 图像的质量为 100%
            // cacheBust: true // 启用缓存破坏，以避免浏览器缓存图像，但可能会导致额外的HTTP请求影响性能
            cacheBust: false // 关闭缓存破坏
          }).then((dataUrl) => {
            // console.log('📢 convert_to_image return_url ', node, dataUrl)
            // let imgBlob = this.global.dataURLToBlob(dataUrl) // url_base64
            let imgBlob = dataUrl
            // url file
            let fileUrl = window.URL.createObjectURL(imgBlob)
            let imgFile = new File([imgBlob], name, {
              type: 'image/png', // 'application/json',
              lastModified: Date.now()
            })
            resolve({ imgBlob, fileUrl, imgFile })
          })
        }, 100)
      })
    },


    downloadImg(url, name) {
      if(url && url.length>0) {
        if(this.global.IsPC()) {
          let path = '/downloads?url=' + url + '&name=' + (name||'invite') + '.png';
          // let path = '/download'
          window.open(path, '_blank');
        } else {
          if(this.global.isWeChatBrowser()) {
            alert('请点击右上角，进入浏览器进行下载');
          } else {
            // console.log( $('.card-download-poster') );
            let node = document.createElement('a')
            node.download = name + '.png';
            node.href = url;
            node.click();
            setTimeout(() => {
              node.remove();
            }, 200)
            // document.getElementsByClassName('card-download-poster')[0].click();
          }
        }
      }
    },

    toDownloads () {
      let url = []
      let name = []
      for (let item of Object.values(this.files)) {
        url.push(item.file)
        name.push(item.name)
      }
      this.downloadImg(JSON.stringify(url), JSON.stringify(name))
    }
  }
}
</script>

<style lang="scss">
.poster-page.page-invite {
  width: calc(100vw - 430px);
  height: 100%;
  padding-top: 100%;
  position: relative;
  z-index: 1;
  .show-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    padding: 30px;
    max-height: calc(100vh - 60px);
    background-color: #f5f5f5;
    overflow: auto;
    .is-blank {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -60px;
      margin-bottom: 0;
    }
    .is-list {
      width: 100%;
      height: auto;
      padding-top: 45px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      h1 {
        font-size: 16px;
        position: absolute;
        top: 30px;
        left: 30px;
        button {
          color: #ffffff;
          padding: 2px 6px;
          font-size: 16px;
          font-weight: bolder;
          border-radius: 4px;
          border-color: #00C3FE70;
          background-color: rgba(240, 240, 240, 0.3);
          background: var(--background-color-button);
          // -webkit-background-clip: text;
          // background-clip: text;
          // -webkit-text-fill-color: transparent;
          opacity: 0.8;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
      img {
        max-width: 500px;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
      }
    }
  }
  .sharecard_model {
    width: fit-content;
    height: fit-content;
    position: relative;
    z-index: 3;
    img {
      position: relative;
      z-index: 3;
    }
    p.ab {
      position: absolute;
      z-index: 4;
    }
  }
  @media (max-width:767px) {
    width: 100%;
    .show-area {
      position: fixed;
      padding: 15px;
    }
  }
}
</style>